import classes from './FindProperty.module.css';
import DescriptionSection from '../../components/DescriptionSection/DescriptionSection';
import ContactUs from '../../components/ContactUs/ContactUs';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Spinner from '../../components/Spinner/Spinner';
import Backdrop from '../../components/Backdrop/Backdrop';
import Popup from '../../components/Popup/Popup';
import * as actions from '../../store/action/index';
import { Redirect } from 'react-router-dom';
const FindProperty = () => {

    const dispatch = useDispatch();

    const [formData,setFormData] = useState({province: "Gauteng",location: "",section: ""});
    const [spinnerState,setSpinnerState] = useState({loading: false,message: ""});
    const [findPropertyState,setFindPropertyState] = useState(false);

    const inputChangedHandler = (event,identifier) => {

        let updatedFormValues = {
                ...formData,
                [identifier]: event.target.value.trim()
        }
        setFormData(updatedFormValues);
    }

    const formHandler = (event) => {
        event.preventDefault();

        if(formData.province.length === 0){
            setSpinnerState({loading: false,message: "Please select province"});
            return;
        }else if(formData.location.length === 0){
            setSpinnerState({loading: false,message: "Please enter location"});
            return;
        }else if(formData.section.length === 0){
            setSpinnerState({loading: false,message: "Please enter section"});
            return;
        }else{
            dispatch(actions.propertysearching(formData.province,formData.location,formData.section,true));

            setFindPropertyState(true);
        }
        

    }

    const removePopUp = (response) => {
        if(response === 'OK'){
            setSpinnerState({loading: false,message: ""});
        }
    }

    let theSpinner = null;
    let backDrop = null;
    let popup = null;

    if(spinnerState.loading){
        theSpinner = <Spinner />
        backDrop = <Backdrop sideDrawerState={spinnerState.loading} />
    }
    if((spinnerState.message).length !== 0){
        popup = <Popup message={spinnerState.message} type="oneButton" clicked={removePopUp}/>
        backDrop = <Backdrop sideDrawerState={true}/>
    }


    let homeSection = <div className={classes.FindProperty}>
                <div className={classes.FindPropertyBackgroundContent}>

                    <div className={classes.FindPropertyHeader}>
                        <h1>FIND ROOM TO RENT IN SA</h1>
                    </div>
                    <div className={classes.FindPropertyUnderline}></div>
                    <div className={classes.FindPropertyFormCotainer}>
                            <form onSubmit={formHandler} className={classes.FindPropertyForm}>

                                    <label htmlFor="province">Province*</label>
                                    <select id="province" onChange={event => inputChangedHandler(event,"province")}>
                                        <option value="Gauteng">Gauteng</option>
                                        <option value="North West">North West</option>
                                        <option value="Mpumalanga">Mpumalanga</option>
                                        <option value="Kwazulu Natal">Kwazulu Natal</option>
                                        <option value="Western Cape">Western Cape</option>
                                        <option value="Limpopo">Limpopo</option>
                                        <option value="Northen Cape">Northen Cape</option>
                                        <option value="Free State">Free State</option>
                                        <option value="Eastern Cape">Eastern Cape</option>
                                    </select>
                                    <label htmlFor="location">Location*</label>
                                    <input type="text" name="location" placeholder="e.g Soweto" value={formData.name} onChange={event => inputChangedHandler(event,"location")} required maxLength="30"/>
                                    <label htmlFor="section">Section*</label>
                                    <input type="text" name="section" placeholder="e.g Pimville" value={formData.name} onChange={event => inputChangedHandler(event,"section")} required maxLength="30"/>
                                    <button>FIND</button>
                            </form>
                    </div>
                    
              </div>
              

           </div>;

    return <div>
               {findPropertyState ? <Redirect to={{pathname: "/available_property",state: { referrer: '/' }}} /> : null}
               {homeSection}
               <div className={classes.Login_Spinner}>
                            {backDrop}
                            {theSpinner}
                </div>
                {popup}
               <DescriptionSection />
               <ContactUs />
          </div>;
}
export default FindProperty;