import React, { useRef } from "react";
import classes from './UploadProperty.module.css';
import DescriptionSection from '../../components/DescriptionSection/DescriptionSection';
import ContactUs from '../../components/ContactUs/ContactUs';
import Spinner from '../../components/Spinner/Spinner';
import Backdrop from '../../components/Backdrop/Backdrop';
import Popup from '../../components/Popup/Popup';
import { useSelector,useDispatch } from 'react-redux';
import * as actions from '../../store/action/index';
import { Redirect } from 'react-router-dom';
import { useState } from 'react';
import firebase from '../../firebaseStorage/firebase';
import { v4 as uuidv4 } from 'uuid';


const UploadProperty = () => {

    const dispatch = useDispatch();
    dispatch(actions.propertysearching('','','',false));

    const username = useSelector(state => {
        return state.auth.username;
    });

    const storageRef = firebase.storage().ref('roomFinder');
    const firestoreRef = firebase.firestore();

    const formDataObject = {province: "Gauteng",location: "",section: "",price: "",address: "",contact: "",decription: ""};

    const [formData,setFormData] = useState(formDataObject);
    const [spinnerState,setSpinnerState] = useState({loading: false,message: ""});
    const [imageFile1,setImageFile1] = useState(null);
    const [imageFile2,setImageFile2] = useState(null);
    const [imageFile3,setImageFile3] = useState(null);
    const [imageFile4,setImageFile4] = useState(null);

    const ref1 = useRef();
    const ref2 = useRef();
    const ref3 = useRef();
    const ref4 = useRef();

    const authenticated = useSelector(state => {
        return state.auth.authenticated;
    });

    const inputChangedHandler = (event,identifier) => {
        
        let updateedFormValues = {
                ...formData,
                [identifier]: event.target.value
        }
        setFormData(updateedFormValues);
    }

    const inputImageChangedHandler = (file,imageNumber) => {
        
        if(!file){
            return;
        }
        //-------------------

        const maxWidth = 800; // Desired maximum width
        const maxHeight = 600; // Desired maximum height


        let resizedFile = null;
    
        // Create a new FileReader instance
        const reader = new FileReader();
    
        // Once the file is read, load it as an image
        reader.onload = (event) => {
          const img = new Image();
          img.src = event.target.result;
    
          img.onload = () => {
            // Calculate new image dimensions while keeping aspect ratio
            let width = img.width;
            let height = img.height;
    
            if (width > maxWidth || height > maxHeight) {
              if (width > height) {
                height = (height * maxWidth) / width;
                width = maxWidth;
              } else {
                width = (width * maxHeight) / height;
                height = maxHeight;
              }
            }
    
            // Create a canvas and draw the image on it with the new dimensions
            const canvas = document.createElement("canvas");
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext("2d");
    
            ctx.drawImage(img, 0, 0, width, height);
    
            // Convert canvas back to file
            canvas.toBlob((blob) => {
              resizedFile = new File([blob], file.name, {
                type: file.type,
                lastModified: Date.now(),
              });
    
              // Now you can use this resizedFile as needed, e.g., upload it
              if(resizedFile.size > 30000000){
                setSpinnerState({loading: false,message: 'Image size is more than 30Mb'});
                if(imageNumber === "image1"){
                    ref1.current.value = "";
                }else if(imageNumber === "image2"){
                    ref2.current.value = "";
                }else if(imageNumber === "image3"){
                    ref3.current.value = "";
                }else if(imageNumber === "image4"){
                    ref4.current.value = "";
                }
                return;
            }
            if(imageNumber === "image1"){
                setImageFile1(resizedFile);
            }else if(imageNumber === "image2"){
                setImageFile2(resizedFile);
            }else if(imageNumber === "image3"){
                setImageFile3(resizedFile);
            }else if(imageNumber === "image4"){
                setImageFile4(resizedFile);
            }


            }, file.type);
          };
        };


    
        // Read the uploaded file as a Data URL
        reader.readAsDataURL(file);

        //-------------------    
    }

    const formHandler = (event) =>  {

        event.preventDefault();

        let tempFormData = {'roomStatus':"Available",province: formData.province.trim(),location: formData.location.trim(),section: formData.section.trim(),price: formData.price.trim(),address: formData.address.trim(),contact: formData.contact.trim(),decription: formData.decription.trim()};

        if(tempFormData.province.length === 0){
            setSpinnerState({loading: false,message: "Please select province"});
            return;
        }else if(tempFormData.location.length === 0){
            setSpinnerState({loading: false,message: "Please enter location"});
            return;
        }else if(tempFormData.section.length === 0){
            setSpinnerState({loading: false,message: "Please enter section"});
            return;
        }else if(tempFormData.price.length === 0 || isNaN(tempFormData.price)){
            setSpinnerState({loading: false,message: "Please enter price"});
            return;
        }else if(tempFormData.address.length === 0){
            setSpinnerState({loading: false,message: "Please enter location"});
            return;
        }else if(!imageFile1){
            setSpinnerState({loading: false,message: "Please upload image 1"});
            return;
        }else if(!imageFile2){
            setSpinnerState({loading: false,message: "Please upload images 2"});
            return;
        }
        // else if(!imageFile3){
        //     setSpinnerState({loading: false,message: "Please upload images 3"});
        //     return;
        // }else if(!imageFile4){
        //     setSpinnerState({loading: false,message: "Please upload images 4"});
        //     return;
        // }

        setSpinnerState({loading: true,message: ''});
        const imageId1 = uuidv4()+''+username;
        const fileRef1 = storageRef.child(imageId1);
        fileRef1.put(imageFile1).then(async () => {
               const imageUrl1 = await fileRef1.getDownloadURL();
               const imageId2 = uuidv4()+''+username;
               const fileRef2 = storageRef.child(imageId2);
               fileRef2.put(imageFile2).then(async () => {
                    const imageUrl2 = await fileRef2.getDownloadURL();
                    // const imageId3 = uuidv4()+''+username;
                    // const fileRef3 = storageRef.child(imageId3);
                    // fileRef3.put(imageFile3).then(async () => {
                    //     const imageUrl3 = await fileRef3.getDownloadURL();


                    //     const imageId4 = uuidv4()+''+username;
                    //     const fileRef4 = storageRef.child(imageId4);
                    //     fileRef4.put(imageFile4).then(async () => {
                    //         const imageUrl4 = await fileRef4.getDownloadURL();
                            const the_date = new Date();  
                            firestoreRef.collection('roomFinder').doc(imageId1).set({
                                ...tempFormData,
                                contact: formData.contact.length === 0 ? 'no contact' : formData.contact,
                                imageUrl: [imageUrl1,imageUrl2],
                                date: the_date.toString(),
                                ids: [imageId1,imageId2]
                            }).then(() => {
                                setFormData(formDataObject);
                                ref1.current.value = "";
                                ref2.current.value = "";
                                // ref3.current.value = "";
                                // ref4.current.value = "";
                                setSpinnerState({loading: false,message: 'Successfully submited'});
                            }).catch((error)=>{
                                setSpinnerState({loading: false,message: error.message});
                            });
                            
                        }).catch(()=>{
                            setSpinnerState({loading: false,message: 'Failed to submit, please try again 2'});
                        });
                        
                    }).catch(()=>{
                        setSpinnerState({loading: false,message: 'Failed to submit, please try again 3'});
                    });
                
        //         });.catch(()=>{
        //             setSpinnerState({loading: false,message: 'Failed to submit, please try again'});
        //         });


            
        // }).catch(()=>{
        //     setSpinnerState({loading: false,message: 'Failed to submit, please try again'});
        // });


    }

    const removePopUp = (response) => {
        if(response === 'OK'){
            setSpinnerState({loading: false,message: ""});
        }
    }

    let theSpinner = null;
    let backDrop = null;
    let popup = null;

    if(spinnerState.loading){
        theSpinner = <Spinner />
        backDrop = <Backdrop sideDrawerState={spinnerState.loading} />
    }
    if((spinnerState.message).length !== 0){
        popup = <Popup message={spinnerState.message} type="oneButton" clicked={removePopUp}/>
        backDrop = <Backdrop sideDrawerState={true} />
    }

    let uploadProperty = null;
    let login = null;

    if(authenticated){
        uploadProperty = <div className={classes.UploadProperty}>
                    <div className={classes.UploadPropertyBackgroundContent}>

                            <div className={classes.UploadPropertyHeader}>
                                <h1>LANDLORD TO UPLOAD ROOM</h1>
                            </div>
                            <div className={classes.UploadPropertyUnderline}></div>
                            
                            <div className={classes.Login_Spinner}>
                                    {backDrop}
                                    {theSpinner}
                            </div>
                            {popup}
                            <div className={classes.UploadPropertyFormCotainer}>
                                    <form onSubmit={formHandler} className={classes.UploadPropertyForm}>

                                            <label htmlFor="province">Province*</label>
                                            <select id="province" onChange={event => inputChangedHandler(event,"province")}>
                                                <option value="Gauteng">Gauteng</option>
                                                <option value="North West">North West</option>
                                                <option value="Mpumalanga">Mpumalanga</option>
                                                <option value="Kwazulu Natal">Kwazulu Natal</option>
                                                <option value="Western Cape">Western Cape</option>
                                                <option value="Limpopo">Limpopo</option>
                                                <option value="Northen Cape">Northen Cape</option>
                                                <option value="Free State">Free State</option>
                                                <option value="Eastern Cape">Eastern Cape</option>
                                            </select>
                                            <label htmlFor="location">Location*</label>
                                            <input type="text" name="location" placeholder="e.g Soweto" value={formData.location} onChange={event => inputChangedHandler(event,"location")} required maxLength="30"/>
                                            <label htmlFor="section">Section*</label>
                                            <input type="text" name="section" placeholder="e.g Pimville" value={formData.section} onChange={event => inputChangedHandler(event,"section")} required maxLength="30"/>
                                            <label htmlFor="price">Price*</label>
                                            <input type="number" name="price" value={formData.price} onChange={event => inputChangedHandler(event,"price")} required maxLength="7"/>
                                            <label htmlFor="address">Address*</label>
                                            <input type="text" name="address" placeholder="e.g Main Street" value={formData.address} onChange={event => inputChangedHandler(event,"address")} required maxLength="50"/>
                                            <label htmlFor="contact">Contact</label>
                                            <input type="tel" name="contact" value={formData.contact} onChange={event => inputChangedHandler(event,"contact")} maxLength="10"/>
                                            <label htmlFor="decription">Decription</label>
                                            <input type="text" name="decription" placeholder="e.g Eletricity included and free Wi-Fire"  value={formData.decription} onChange={event => inputChangedHandler(event,"decription")} maxLength="60"/>
                                            <label htmlFor="image">Images*</label>
                                            <input type="file" name="image" accept="image/*" ref={ref1}  onChange={event => inputImageChangedHandler(event.target.files[0],"image1")} />
                                            <input type="file" name="image" accept="image/*" ref={ref2}  onChange={event => inputImageChangedHandler(event.target.files[0],"image2")} />
                                            {/* <input type="file" name="image" accept="image/*" ref={ref3}  onChange={event => inputImageChangedHandler(event.target.files[0],"image3")} />
                                            <input type="file" name="image" accept="image/*" ref={ref4}  onChange={event => inputImageChangedHandler(event.target.files[0],"image4")} /> */}
                                            <button>SUBMIT</button>
                                    </form>
                            </div>
                            
                    </div>
                    

                </div>;
           }else{
               login = <Redirect to={{
                            pathname: "/login",
                            state: { referrer: "/upload_property" }
                        }} />;
           }

           return <div>
                      {uploadProperty}
                      {login}
                      <DescriptionSection />
                      <ContactUs />
                  </div>;
}
export default UploadProperty;